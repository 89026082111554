import React from "react"

import { Helmet } from 'react-helmet'

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"

export const query = graphql`
    query {
        img01: file(relativePath: { eq: "portfolio/project-westpac-01.png" }) {
            ...projectImg
        }
        img02: file(relativePath: { eq: "portfolio/project-westpac-02.png" }) {
            ...projectImg
        }
        img03: file(relativePath: { eq: "portfolio/project-westpac-03.png" }) {
            ...projectImg
        }
    }
`
const ProjectWestpacPage = ({ data }) => (
  <>
    <Seo title="Westpac OTP project - Scott Luxford" />
    <Helmet>
      <body className="projects projects-theme-westpac" />
    </Helmet>
    <Header />
    <main className="project" id="top">
      <article>

			<Link className="project-crumb" to="/projects"><span className="sr-only">Back to</span><span role="presentation">&lt;</span> Projects</Link>
			<h1 className="heading-offset">Westpac OTP</h1>

			<ul>
				<li><strong>Role</strong>
				Lead UI Developer</li>

				<li><strong>Responsibilities</strong>
				HTML, CSS, JavaScript, Design System, Accessibility, Quality Assurance, WCAG 2.0</li>

				<li><strong>Company</strong>
				Fiserv for Westpac</li>

				<li><strong>When</strong>
				2013 - 2014</li>
			</ul>

			<section className="section section-text-first">

				<div className="section-text">
					<h2>Background</h2>

					<p>A big shift from the agency work I'd come from; an opportunity to join the UI team for the Westpac Online Transformation project.</p>

					<p>With most of the CX design in the bag, I was engaged to assist in developing and championing the UI pattern library, as well as advocating and supporting its use throughout the large spread of feature teams on and offshore.</p>

					<p>This project was most certainly a team effort and I can't share work that is explicitly my own, I’d love to point out my contributions and learnings.</p>
				</div>

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img01)} alt="Large Westpac 'W' logo" />
				</figure>

			</section>

			<section className="section section-media-first">

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img02)}  alt="Complicated user interface of the Westpac Live sign in view" />
				</figure>

				<div className="section-text">
					<h2>Accessibility</h2>

					<p>Our major focus was to develop UI components that could satisfy the WCAG 2.0 AA level spec.</p>

					<p>This involved heavy keyboard and low vision testing and solutioning (days and days in screen readers!), ensuring that requirements were met early and could be implemented as efficiently as possible without regression.</p>

					<p>We were lucky to work with some of Australia's most experienced designers and developers, and got to meet some truly inspiring staff and users. I learned a lot and I'm grateful to be able to take this experience to my future opportunities.</p>
				</div>

			</section>

			<section className="section section-text-first">

				<div className="section-text">
					<h2>HTML + CSS Expertise</h2>

					<p>When implementing an accessibility-first model for markup, styling a component to meet web standards as well as branding guidelines can be challenging.</p>

					<p>I was originally engaged to provide the CSS 'wizardry' to ensure the UI could represent the requirements of our customers, uphold the clients brand and make it easy for the 90+ developers to adopt and use the 150+ UI patterns.</p>

					<p>This project saw the largest customer exposure my work has achieved, with the eventual release of our work being used by ~ 4 million customers.</p>
				</div>

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img03)} alt="Westpac Live launch usage statistics. 2.7 million personal banking customers and 800000 business banking customers" />
				</figure>

			</section>

        </article>

      <p className="back-links"><a href="#top">Back to top</a> <span role="presentation">/</span> <Link to="/projects">Back to projects</Link></p>
    </main>
    <Footer />
  </>
)

export default ProjectWestpacPage
